//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import flickity from '~/node_modules/vue-flickity/src/flickity';

import {mapGetters} from 'vuex';

export default {
    name: 'blockproductslideshow',
    components: {
        flickity
    },
    props: {
        content: Object
    },
    mounted(){

        let self = this;

        try{
            const kirby = this.$axios.$post('api/query', {
                query: "page('shop')",
                select: {
                    "shop_layout": "page.storeLayout.toShopLayoutHelper"
                }
            }).then((kirby) => {

                const kirbyProducts = kirby.result.shop_layout;
                self.$shopify.product.fetchMultiple(kirbyProducts.map(i => i.shopify_id)).then((shopifyProducts) => {
                    self.related_products = kirbyProducts.map(i => {
                        i.shopify = shopifyProducts.find(p => p.id === i.shopify_id)
                        return i;
                    })
                    setTimeout(()=>{
                        //Images loaded in, we need to trigger a re-render to get the right height
                        this.$refs.flickity.rerender();
                    },10);
                    setTimeout(()=>{
                        //IDK how to hook into "all shopify images are loaded in"... spam this for a second?
                        this.$refs.flickity.rerender();
                    },1000);
                })
            }) 
        }catch(error){
            if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
        }
        
    },
    data(){
        return {
            related_products: [],
            flickityOptions: {
                pageDots: true,
                wrapAround: false,
                setGallerySize: true,
                prevNextButtons: false,
                contain: true,
                cellAlign: "left"
            }
        }
    },
    computed: {
        products(){
            if(typeof(this.content) != 'undefined')
                return [];
            else 
                return this.content?.products;
        }
    }, 
    watch: {
        related_products(val){
            console.log('related', val.length);
            setTimeout(()=>{
                this.$refs.flickity.rerender();
                this.$refs.flickity.on('dragStart', () => this.$refs.flickity.$el.style.pointerEvents = "none");
                this.$refs.flickity.on('dragEnd', () => this.$refs.flickity.$el.style.pointerEvents = "auto");
            }, 100)
        }
    }
}
