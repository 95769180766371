//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'product-card',
    props: {
        product: {
            type: Object,
            required: true
        },
        fix_height:{
            type: Boolean
        }
    },
    data(){
        return {          
            enable_shop: process.env.NUXT_DISABLE_SHOP == "false"
        }
    },
    computed: {
        featured_image(){
            if(this.product.featured_image) return this.product.featured_image;
            return product.shopify.images[0].src;
        }
    },
    methods: {
        addToCart(){
            this.$store.dispatch('shopify/add_to_cart', {
                variantId: this.product.shopify.variants[0].id, 
                quantity: 1
            })
            .then(()=>{
                this.$store.dispatch('shopify/show_checkout');
            })
        }
    }
}
