import { render, staticRenderFns } from "./blockproductslideshow.vue?vue&type=template&id=b79ec63c&"
import script from "./blockproductslideshow.vue?vue&type=script&lang=js&"
export * from "./blockproductslideshow.vue?vue&type=script&lang=js&"
import style0 from "./blockproductslideshow.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductCard: require('/opt/build/repo/frontend/components/shop/product-card.vue').default})
